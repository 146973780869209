import env from '../env.js'
import countries from './db/countries.js'
import stateOptions from './db/states.js'
import { getUserData } from '@/helpers/utils'

const state = {
    isLoading: false,
    AppActiveUser: getUserData(),
    domain: env.domain,
    localUserKey: env.localUserKey,
    appName: env.appName,
    config:{
      shortDesc: "Electronic devices insurance company",
      appEmail: "support@morisowireless.com"
    },
    scrollY: 0,
    windowWidth: window.innerWidth,
    countries: countries,
    stateOptions: stateOptions,
    coupons : [],
    plans:[],
    subscriptions:[],
    categories:[],
    storeTypes:[],
    partnerTypes:[
      {
          "title": "Cellular Distributor",
          "type": "CELLULAR_DISTRIBUTOR"
      },
      {
          "title": "Affiliate",
          "type": "AFFILIATE"
      },
      {
          "title": "Electronics Wholesaler",
          "type": "ELECTRONICS_WHOLESALER"
      },
      {
          "title": "Electronics Seller",
          "type": "ELECTRONICS_SELLER"
      },
      {
          "title": "Electronics Distributor",
          "type": "ELECTRONICS_DISTRIBUTOR"
      },
      {
          "title": "Cellular Retail plus Electronics Repair",
          "type": "CELLULAR_RETAIL_PLUS_ELECTRONICS_REPAIR"
      },
      {
          "title": "Cellular Retail",
          "type": "CELLULAR_RETAIL"
      },
      {
          "title": "Electronic Repair",
          "type": "ELECTRONIC_REPAIR"
      },
      {
          "title": "Agent / Broker",
          "type": "AGENT_OR_BROKER"
      }
    ],
}

export default state
