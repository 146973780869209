import axios from "@/plugins/axios.js"

const actions = {
  fetchStores({ commit, state }) {
    if(state.list.length) return ;
    return new Promise((resolve, reject) => {
      axios.get('/stores')
      .then((response) => {
        if(response.data.success){
          commit('SET_STORES', response.data.data)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
}

const mutations = {
  ADD_STORE(state, item){
    state.list.unshift(item);
  },
  SET_STORES(state, items){
    state.list = [...items];
  },
  UPDATE_STORE(state, item){
    const dataIndex = state.list.findIndex((f) => f.id == item.id)
    Object.assign(state.list[dataIndex], item)
  },
  DELETE_STORE(state, itemId){
    const ItemIndex = state.list.findIndex((item) => item.id == itemId)
    state.list.splice(ItemIndex, 1);
  },
}


const state = {
  list:[]
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

