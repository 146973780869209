export default [
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '/main',
        redirect: '/',
        component: () => import('@/layouts/Main.vue'),
        children: [
            {
              path: '/',
              name: 'dashboard',
              component: () => import('@views/Dashboard.vue'),
              meta: {
                title: 'Dashboard',
                authRequired: true,
              }
            },
            {
              path: '/items',
              name: 'items',
              component: () => import('@views/items/ItemList.vue'),
              meta: {
                title: "Items",
                authRequired: true,
              },
            },
            {
              path: '/items/:itemId(\\d+)',
              name: 'view-item',
              component: () => import('@views/items/ItemShow.vue'),
              meta: {
                title: "View item",
                authRequired: true,
              },
            },
            {
              path: '/claims',
              name: 'claims',
              component: () => import('@views/claims/ClaimList.vue'),
              meta: {
                title: "Claims",
                authRequired: true,
              },
            },
            {
              path: '/claims/file-new',
              name: 'file-new-claim',
              component: () => import('@views/claims/AddNewClaim.vue'),
              meta: {
                title: "File new claims",
                authRequired: true,
              },
            },
            {
              path: '/claims/lookup',
              name: 'lookup-claim',
              component: () => import('@views/claims/ClaimLookup.vue'),
              meta: {
                title: 'Lookup claim',
                authRequired: true,
              },
            },
            {
              path: '/claims/:claimId(\\d+)',
              name: 'view-claims',
              component: () => import('@views/claims/ClaimShow.vue'),
              meta: {
                title: "View claim",
                authRequired: true,
              },
            },
            {
              path: '/customers',
              name: 'customers',
              component: () => import('@views/customers/Customers.vue'),
              meta: {
                title: 'Customers',
                authRequired: true,
              },
            },
            {
              path: '/stores',
              name: 'stores',
              component: () => import('@views/stores/StoreList.vue'),
              meta: {
                title: 'Stores',
                authRequired: true,
              },
            },
            {
              path: '/stores/:storeId(\\d+)',
              name: 'view-store',
              component: () => import('@views/stores/StoreShow.vue'),
              meta: {
                title: "View store",
                authRequired: true,
              },
            },
            {
              path: '/plans/register',
              name: 'plan-register',
              component: () => import('@views/plans/PlanRegister.vue'),
              meta: {
                title: 'Plans',
                authRequired: true,
              },
            },
            {
              path: '/subscriptions',
              name: 'subscription',
              component: () => import('@views/subscriptions/SubscriptionList.vue'),
              meta: {
                title: 'Subscriptions',
                authRequired: true,
              },
            },
            {
              path: '/subscriptions/:subId(\\d+)',
              name: 'view-subscription',
              component: () => import('@views/subscriptions/SubscriptionShow.vue'),
              meta: {
                title: "View subscription",
                authRequired: true,
              },
            },
            {
              path: '/appointments',
              name: 'appointments',
              component: () => import('@views/appointments/AppointmentList.vue'),
              meta: {
                title: 'Appointments',
                authRequired: true,
              },
            },
            {
              path: '/appointments/:appointId(\\d+)',
              name: 'view-appointment',
              component: () => import('@views/appointments/AppointmentShow.vue'),
              meta: {
                title: "View appointment",
                authRequired: true,
              },
            },
            {
              path: '/leases',
              name: 'leases',
              component: () => import('@views/leases/LeaseList.vue'),
              meta: {
                title: 'Lease Activity',
                authRequired: true,
              },
            },
            {
              path: '/leases/application',
              name: 'lease-application',
              component: () => import('@views/leases/Application.vue'),
              meta: {
                title: 'Lease application',
                authRequired: true,
              },
            },
            {
              path: '/leases/estimate',
              name: 'lease-estimate',
              component: () => import('@views/leases/Calculator.vue'),
              meta: {
                title: 'Lease estimate',
                authRequired: true,
              },
            },
            {
              path: '/leases/:leaseId(\\d+)',
              name: 'view-lease',
              component: () => import('@views/leases/LeaseShow.vue'),
              meta: {
                title: "View Lease",
                authRequired: true,
              },
            },
            {
              path: '/leases/:leaseId(\\d+)/checkout',
              name: 'lease-checkout',
              component: () => import('@views/leases/LeaseCheckout.vue'),
              meta: {
                title: "Lease checkout",
                authRequired: true,
              },
            },
            {
              path: '/sale-report',
              name: 'sale-report',
              component: () => import('@views/sales/SaleReport.vue'),
              meta: {
                title: 'Sales Report',
                authRequired: true,
              },
            },
            {
              path: '/device-plan',
              name: 'device-plan',
              component: () => import('@views/plans/DevicePlan.vue'),
              meta: {
                title: 'Device plan',
                authRequired: true,
              },
            },
            {
              path: '/account',
              name: 'account',
              component: () => import('@views/account/UserEdit.vue'),
              meta: {
                title: 'Profile',
                authRequired: true,
              },
          },
          {
            path: '/newsletter',
            name: 'newsletter',
            component: () => import('@views/settings/Newsletter.vue'),
            meta: {
              title: 'Newsletter',
              authRequired: true,
            },
          },
          {
            path: '/notifications',
            name: 'notifications',
            component: () => import('@views/notification/NotificationList.vue'),
            meta: {
              title: "Notifications",
              authRequired: true,
            }
          },
        ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
        path: '/full',
        component: () => import('@/layouts/Full.vue'),
        children: [
          {
              path: '/login',
              name: 'login',
              component: () => import('@/views/pages/Login.vue'),
              meta: {
                guestRequired: true,
                title: "Login"
              }
          },
          {
            path: '/register',
            name: 'register',
            component: () => import('@/views/pages/Register.vue'),
            meta: {
              guestRequired: true,
              title: "Register"
            }
          },
          {
              path: '/error-404',
              name: 'error-404',
              component: () => import('@/views/pages/Error404.vue'),
              meta: {
                title: "404 - Page not Found"
              }
          },
          {
              path: '/error-500',
              name: 'error-500',
              component: () => import('@/views/pages/Error500.vue'),
              meta: {
                title: "500 - Server error"
              }
          },
        ]
    },
    // Redirect to 404 page, if no match found
    {
        path: '/:any(.*)',
        redirect: '/error-404'
    },

]