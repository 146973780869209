import axios from "@/plugins/axios.js"

const actions = {
  fetchClaims({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/claims?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_CLAIMS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
}

const state = {
  list: []
}

const mutations = {
  ADD_CLAIM(state, claim){
    state.list.unshift(claim);
  },
  SET_CLAIMS(state, claims){
    state.list = [...claims];
  },
  UPDATE_CLAIM(state, claim){
    const dataIndex = state.list.findIndex((p) => p.id == claim.id)
    Object.assign(state.list[dataIndex], claim)
  },
  DELETE_CLAIM(state, claimId){
    const ItemIndex = state.list.findIndex((claim) => claim.id == claimId)
    state.list.splice(ItemIndex, 1);
  },
}

export default {
  isRegistered: true,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}

